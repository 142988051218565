import React from "react";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Home from './Home';
import About from './About';
import Users from "./Users";
import NotFound from './NotFound';

function App() {
  // const [comp, setComp] = useState();
  return (
    <Router>
      <header>
        <Link to="/">
          <button>Home</button>
        </Link>
        <Link to='/about'>
          <button>About</button>
        </Link>
        <Link to='/users'>
          <button>Users</button>
        </Link>
      </header>
      <hr />
      <main>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/users" component={Users} />
          <Route component={NotFound} />
        </Switch>
      </main>
    </Router>
  );
}

export default App;
