import React from "react";

function Home() {
  return (
    <>
      <h1>Home</h1>
      <p>Welcome home!</p>
    </>
  );
}

export default Home;
